<template>
    <div class="container-fluid listing-events-preview-widget listing-events-widget">

        <div class="listing-events-filters d-flex justify-content-start justify-content-md-center">
            <button @click='onChangeCategory(0)' class="btn btn-listing-filter filter-active" id="filter-events-0">{{
                    allString }}</button>
            <button v-for="(value, key) in categories" :key="key" @click="onChangeCategory(key)"
                    :id="`filter-events-${key}`" class="btn-listing-filter">{{ value }}</button>
        </div>

        <div>
            <div v-for="(event, index) in events" class="row-event w-100 d-flex" :key="event.id"
                 v-if="(events.filter((element) => element.category === categories[selectedCategory]).length > 0 && selectedCategory !== 0) ||
                ((selectedCategory === '' || selectedCategory === 0) && events.length > 0)">
                <div class="container d-flex align-items-center">
                    <div class="calendar-cell d-flex">
                        <div
                            :class="{ 'd-flex': true, 'flex-column': true, 'align-items-center': true, 'calendar-info': true, 'calendar-flip': isRtl == 1 }">
                            <div
                                :class="{ 'd-lg-flex': true, 'd-none': isRtl != 1, 'flex-column': true, 'align-items-center': true, 'd-flex': isRtl == 1 }">
                                <p class="calendar-day">{{ event.dateTimes.start.day }}</p>
                                <p class="calendar-month">{{ event.dateTimes.start.month }}</p>
                            </div>
                            <div
                                :class="{ 'd-lg-none': true, 'd-flex': true, 'align-items-center': true, 'event-date-mobile': true, 'd-none': isRtl == 1 }">
                                <p class="calendar-month">
                                    {{ event.dateTimes.start.month.length > 3 ? event.dateTimes.start.month.slice(0, 3) : event.dateTimes.start.month }}
                                </p>
                                <p class="calendar-day">{{ event.dateTimes.start.day }}</p>
                            </div>
                            <p class="event-time d-flex d-lg-none" v-if="isRtl != 1" :style="{ marginBottom: 0 }">
                                {{ event.dateTimes.start.time }}</p>
                            <p class="event-time d-flex d-lg-none text-center" v-if="isRtl != 1">
                                {{ event.dateTimes.end.time }}<br />{{timezoneAbbreviation}}</p>
                        </div>
                        <svg width="102" height="113" viewBox="0 0 102 113" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.90203 13.2035C0.90203 9.7163 3.729 6.88933 7.21624 6.88933H94.7841C98.2713 6.88933 101.098 9.7163 101.098 13.2035V26.734H0.90203V13.2035Z"
                                fill="#F5FCFD" stroke="#C8CEC9" stroke-width="1.80406" />
                            <path d="M17.9062 2.37891V13.2033" stroke="#C8CEC9" stroke-width="3.60812"
                                  stroke-miterlimit="10" stroke-linecap="round" />
                            <path d="M84.0703 2.37891V13.2033" stroke="#C8CEC9" stroke-width="3.60812"
                                  stroke-miterlimit="10" stroke-linecap="round" />
                            <g clip-path="url(#clip0_6270_5076)">
                                <path
                                    d="M0 25.832H102V104.852C102 108.838 98.7695 112.069 94.7841 112.069H7.21624C3.23082 112.069 0 108.838 0 104.852V25.832Z"
                                    fill="white" />
                            </g>
                            <path
                                d="M0.90203 26.7341H101.098V104.852C101.098 108.34 98.2713 111.167 94.7841 111.167H7.21624C3.729 111.167 0.90203 108.34 0.90203 104.852V26.7341Z"
                                stroke="#C8CEC9" stroke-width="1.80406" />
                            <defs>
                                <clipPath id="clip0_6270_5076">
                                    <path
                                        d="M0 25.832H102V104.852C102 108.838 98.7695 112.069 94.7841 112.069H7.21624C3.23082 112.069 0 108.838 0 104.852V25.832Z"
                                        fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="info-event-cell d-flex col flex-column preview-cell col-lg-4">
                        <p class="event-title h4">{{ event.title }}</p>
                        <p class="event-description">{{ event.shortDescription }}</p>
                        <a :class="{ 'btn-arrow-flip': isRtl == 1, 'btn-arrow': true, 'd-flex': true, 'd-lg-none': true, 'align-items-center': true }"
                           :href="`${event.path}`">
                            {{ attendEvent }}<span></span>
                        </a>
                    </div>
                    <div class="date-event-cell col preview-cell d-none d-lg-flex justify-content-center">

                        <p class="event-date">{{ event.dateTimes.start.date }}<br>
                            {{ event.dateTimes.start.date !== event.dateTimes.end.date ?
                                event.dateTimes.end.date : "" }}
                        </p>
                    </div>
                    <div class="time-event-cell d-none d-lg-flex flex-row col preview-cell align-items-center">
                        <p class="event-time">
                            {{ event.dateTimes.start.time }}<br/>{{ event.dateTimes.end.time }}
                        </p>
                        <span class="mt-auto ms-1">{{timezoneAbbreviation}}</span>
                    </div>
                    <div class="time-event-button col preview-cell d-none d-lg-flex justify-content-end">
                        <a class="btn btn-primary" :href="`${event.path}`">
                            {{ attendEvent }}
                        </a>
                    </div>
                </div>
            </div>

            <div v-else-if="events.length == 0 && !loading">
                <p class="d-flex justify-content-center mt-4">{{ noResults }}</p>
            </div>

            <div v-if="loading" class="listng-events-loader-wrapper d-flex justify-content-center align-items-center">
                <div class="spinner-border" role="status"></div>
            </div>

            <div class="button-load-more d-flex justify-content-center" v-if="showLoadMore && !loading">
                <button class="btn btn-secondary" @click="loadMore">{{ loadMoreEvents }}</button>
            </div>
        </div>

        <!--paginazione-->
        <nav aria-label="pagination" class="d-none">
            <ul class="pagination">
                <li v-if="Math.ceil(parseInt(totalResults) / parseInt(this.itemPerPage)) > 1 && page > 1"
                    class="page-item">
                    <a class="page-link" :href="`${absolutePath}?page=${page - 1}`">{{ previousString }}</a>
                </li>
                <li :class="{ 'page-item': true, 'active': index == page }"
                    v-for="index in  Math.ceil(parseInt(totalResults) / parseInt(this.itemPerPage))"><a
                    class="page-link" :href="absolutePath + '?page=' + index">{{ index }}</a></li>
                <li v-if="Math.ceil(parseInt(totalResults) / parseInt(this.itemPerPage)) > 1 && page < Math.ceil(parseInt(totalResults) / parseInt(this.itemPerPage))"
                    class="page-item">
                    <a class="page-link" :href="`${absolutePath}?page=${page + 1}`">{{ nextString }}</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        defaultLang: {
            type: String,
            required: true
        },
        currentLang: {
            type: String,
            required: true
        },
        categoriesList: {
            type: Object,
            required: true
        },
        isRtl: {
            type: String,
            required: true
        },
        attendEvent: {
            type: String,
            required: true
        },
        allString: {
            type: String,
            required: true
        },
        noResults: {
            type: String,
            required: true
        },
        previousString: {
            type: String,
            required: true
        },
        nextString: {
            type: String,
            required: true
        },
        loadMoreEvents: {
            type: String,
            required: true
        },
        schoolDomain: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            itemPerPage: 8,
            selectedCategory: '',
            page: 1,
            returnAllEvents: false,
            events: [],
            showLoadMore: false,
            totalResults: 1,
            timezoneAbbreviation: ''
        };
    },
    computed: {
        categories() {
            return this.categoriesList;
        },
        absolutePath() {
            return window.location.href.substring(0, window.location.href.indexOf('?'))
        }
    },
    methods: {
        async fetchEvents(requestType = null) {
            this.loading = true;

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();
            urlencoded.append("return-all-events", this.returnAllEvents);
            urlencoded.append("page", this.page);
            urlencoded.append("lang", this.currentLang ?? 'en');
            urlencoded.append("default-lang", this.defaultLang ?? 'en');
            urlencoded.append("filter", this.selectedCategory ? this.selectedCategory : "");
            urlencoded.append("items-per-page", this.itemPerPage.toString());
            urlencoded.append("school-domain", this.schoolDomain);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };

            try {
                const response = await fetch("/api/get-events", requestOptions);
                const result = await response.json();

                if (requestType === "loadMore") {
                    // nel caso di load more aggiungo i nuovi eventi alla lista esistente
                    this.events = this.events.concat(result.events);
                } else {
                    this.events = result.events;
                    this.totalResults = result.totalResults;
                    this.timezoneAbbreviation = result.timezoneAbbreviation;
                }
                // recupero info per gestire la visibilità del button "Load more"
                this.showLoadMore = result.showLoadMore;

                if (Math.ceil(parseInt(this.totalResults) / parseInt(this.itemPerPage)) > 1 && this.page > 1) {
                    this.removeLinkFromHead("prev");
                    const link = document.createElement('link');
                    link.href = `${this.absolutePath}?page=${this.page - 1}`;
                    link.rel = 'prev';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }

                if (Math.ceil(parseInt(this.totalResults) / parseInt(this.itemPerPage)) > 1 && this.page < Math.ceil(parseInt(this.totalResults) / parseInt(this.itemPerPage))) {
                    this.removeLinkFromHead("next");
                    const link = document.createElement('link');
                    link.href = `${this.absolutePath}?page=${this.page + 1}`;
                    link.rel = 'next';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }

                if(this.page === Math.ceil(parseInt(this.totalResults) / parseInt(this.itemPerPage))) {
                    this.removeLinkFromHead("next");
                }

                // Aggiornamento dell'URL con i nuovi parametri
                this.updateUrl();

            } catch (error) {
                // TODO
                console.error('Errore nel recupero degli eventi:', error);
            } finally {
                this.loading = false;
            }
            if (this.selectedCategory) {
                document.querySelector("#filter-events-0")?.classList.remove("filter-active")
                document.querySelector(`#filter-events-${this.selectedCategory}`)?.classList.add("filter-active")
            } else {
                document.querySelector("#filter-events-0")?.classList.add("filter-active")
            }
        },
        async onChangeCategory(idCategory) {
            this.page = 1;
            this.events = [];
            this.loading = true;
            this.returnAllEvents = false;
            if (this.selectedCategory === idCategory) {
                // se la category è già selezionata la rimuovo
                this.selectedCategory = '';
                document.querySelector(`#filter-events-${idCategory}`)?.classList.remove("filter-active")
            } else {
                this.selectedCategory = idCategory;
                const buttons = Array.from(document.querySelectorAll(".btn-listing-filter"))
                buttons.map(element => {
                    if (element?.classList.contains("filter-active")) {
                        element?.classList.remove("filter-active");
                    }
                });
                document.querySelector(`#filter-events-${idCategory}`)?.classList.add("filter-active")
            }
            await this.fetchEvents();
        },
        async loadMore() {
            this.page++;
            this.returnAllEvents = false;
            await this.fetchEvents("loadMore");
        },
        updateUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            if (this.page !== 1) {
                urlParams.set('page', this.page);
            } else {
                urlParams.delete('page');
            }
            if (this.selectedCategory !== '' && this.selectedCategory !== 0) {
                urlParams.set('category', this.categoriesList[this.selectedCategory]);
            } else {
                // Rimuovi il parametro 'category' se il filtro non è applicato
                urlParams.delete('category');
            }
            const queryString = urlParams.toString();
            const newUrl = `${location.pathname}${queryString ? `?${queryString}` : ''}`;
            history.pushState({}, '', newUrl);
        },
        parseUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get('page');
            const currentCategory = urlParams.get('category');
            if (page) this.page = parseInt(page);

            if (currentCategory) {
                Object.keys(this.categoriesList).forEach((key) => {
                        if(this.categoriesList[key].toLowerCase() === currentCategory.toLowerCase()) {
                            this.selectedCategory = key;
                        }
                    }
                );
            };
        },
        formatDate(dateString) {
            var parts = dateString.split(' ');
            var datePart = parts[0];
            var dateComponents = datePart.split('-');
            var formattedDate = dateComponents[0] + '/' + dateComponents[1] + '/' + dateComponents[2];
            return formattedDate;
        },
        getMonthText(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('it-IT', { month: 'long' });
        },
        getDay(dateString) {
            const date = dateString.split('-');
            let day = date[0];
            return day;
        },
        getMonth(dateString) {
            const date = new Date(dateString);
            return date.getMonth() + 1;
        },
        getTimeFormatted(timeString) {
            const [datePart, timePart] = timeString.split(' ');
            const [hours, minutes] = timePart.split(':');
            const hour = parseInt(hours, 10);
            const minute = parseInt(minutes, 10);
            const period = hour >= 12 ? 'pm' : 'am';
            const formattedHour = hour % 12 || 12;
            const formattedTime = `${formattedHour}:${minute.toString().padStart(2, '0')} ${period}`;
            return formattedTime;
        },
        checkNewsLenghOnChangeCategory() {
            let catName = this.categories[this.selectedCategory];



            this.events.forEach((element) => {

            })

            return false
        },
        removeLinkFromHead(attributeName) {
            const linkElements = document.head.querySelectorAll('link');

            linkElements.forEach(link => {
                if (link.getAttribute('rel') === attributeName) {
                    link.remove();
                }
            });
        }
    },
    mounted() {
        // Recupero gli eventi all'atterraggio in pagina
        this.parseUrl();
        if (this.page > 1) {
            this.returnAllEvents = true;
        }

        this.fetchEvents();
    }
};
</script>
