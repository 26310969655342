<template>
    <div>
        <div class="row d-flex justify-content-center">
            <div class="col-12 mt-5 mt-md-0 overflow-auto">
                <div class="d-flex justify-content-center w-100 h-100 mt-auto">
                    <div
                        class="d-flex flex-row-reverse justify-content-md-start overflow-auto listing-news-tags-wrapper mt-auto"
                    >
                        <button
                            v-for="(value, key) in categories"
                            :id="`filter-news-${key}`"
                            @click="onChangeCategory(key)"
                            class="btn-listing-filter text-nowrap btn mx-2"
                        >
                            {{ value }}
                        </button>
                        <button
                            id="filter-news-0"
                            @click="onChangeCategory(0)"
                            class="text-nowrap btn mx-2 btn btn-listing-filter filter-active"
                            tabindex="0"
                            autofocus="autofocus"
                        >
                            {{ all }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div v-if="news.length > 0" class="row my-3">
                <div
                    v-for="(newsItem, index) in news"
                    :key="newsItem.id"
                    class="my-2"
                    :class="
                        index <= 1 && windowWidth > 769
                            ? 'col-6'
                            : index > 1 && windowWidth > 769
                            ? 'col-6 col-xl-4'
                            : 'col-12'
                    "
                >
                    <div
                        class="h-100"
                        v-if="index <= 1 || (index > 1 && windowWidth > 769)"
                    >
                        <div class="card news-card h-100">
                            <div class="position-relative">
                                <div
                                    :class="
                                        index <= 1 && windowWidth > 769
                                            ? 'listing-news-card-img-big'
                                            : 'listing-news-card-img-small'
                                    "
                                    v-html="newsItem.mainImage"
                                ></div>
                                <div
                                    class="position-absolute listing-news-label-tag d-flex flex-column align-items-end"
                                >
                                    <p
                                        class="label-tag mb-1"
                                        v-for="category in newsItem.category"
                                    >
                                        {{ category }}
                                    </p>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="card-text">
                                    <small class="text-muted">{{
                                        formatDate(
                                            newsItem.date,
                                            newsItem.month
                                        )
                                    }}</small>
                                </p>
                                <h5 class="card-title h4">
                                    {{ newsItem.title }}
                                </h5>
                                <p class="card-text card-paragraph">
                                    {{ newsItem.shortDescription }}
                                </p>
                                <div class="listing-news-details-link">
                                    <a
                                        :href="newsItem.path"
                                        class="stretched-link btn-arrow"
                                        :class="{
                                            'btn-arrow-flip': isRtl == 1,
                                        }"
                                    >
                                        <span>{{ moreDetails }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else
                        class="mb-3 w-100 d-flex justify-content-center"
                    >
                        <a :href="newsItem.path" class="w-100">
                            <div
                                class="listing-news-card pt-3 ps-3 pe-3 d-flex"
                            >
                                <div
                                    class="listing-news-card-img overflow-hidden"
                                    v-html="newsItem.mainImage"
                                ></div>
                                <div class="me-3 pt-2">
                                    <p
                                        class="label-tag listing-news-label-tag"
                                        v-for="category in newsItem.category"
                                    >
                                        {{ category }}
                                    </p>

                                    <p class="listing-news-card-date mt-4">
                                        {{
                                            formatDate(
                                                newsItem.date,
                                                newsItem.month
                                            )
                                        }}
                                    </p>
                                    <h4 class="listing-news-card-title h4">
                                        {{ newsItem.title }}
                                    </h4>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div v-else-if="news.length == 0 && !loading">
                <p class="d-flex justify-content-center mt-4">
                    {{ noNewsString }}
                </p>
            </div>
            <div
                v-if="loading"
                class="listng-news-loader-wrapper d-flex justify-content-center align-items-center"
            >
                <div class="spinner-border" role="status"></div>
            </div>
            <div
                class="d-flex justify-content-center align-items-center mt-5"
                v-if="!loading"
            >
                <button
                    class="btn btn-secondary mt-3"
                    v-if="showLoadMore"
                    @click="loadMore"
                >
                    {{ loadMoreNews }}
                </button>
            </div>
        </div>
    </div>

    <nav aria-label="pagination" class="d-none">
        <ul class="pagination">
            <li
                v-if="
                    Math.ceil(
                        parseInt(totalResults) / parseInt(this.itemPerPage)
                    ) > 1 && page > 1
                "
                class="page-item disabled"
            >
                <a
                    class="page-link"
                    :href="`${absolutePath}?page=${page - 1}`"
                    >{{ previousString }}</a
                >
            </li>
            <li
                :class="{ 'page-item': true, active: index == page }"
                v-for="index in Math.ceil(
                    parseInt(totalResults) / parseInt(this.itemPerPage)
                )"
            >
                <a class="page-link" :href="absolutePath + '?page=' + index">{{
                    index
                }}</a>
            </li>
            <li
                v-if="
                    Math.ceil(
                        parseInt(totalResults) / parseInt(this.itemPerPage)
                    ) > 1 &&
                    page <
                        Math.ceil(
                            parseInt(totalResults) / parseInt(this.itemPerPage)
                        )
                "
                class="page-item"
            >
                <a
                    class="page-link"
                    :href="`${absolutePath}?page=${page + 1}`"
                    >{{ nextString }}</a
                >
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        widgetId: {
            type: String,
            required: true,
        },
        moreDetails: {
            type: String,
            required: true,
        },
        all: {
            type: String,
            required: true,
        },
        loadMoreNews: {
            type: String,
            required: true,
        },
        loadingString: {
            type: String,
            required: true,
        },
        currentLang: {
            type: String,
            required: true,
        },
        categoriesList: {
            type: Object,
            required: true,
        },
        noNewsString: {
            type: String,
            required: true,
        },
        errorGettingNews: {
            type: String,
            required: true,
        },
        isRtl: {
            type: String,
            required: true,
        },
        previousString: {
            type: String,
            required: true,
        },
        nextString: {
            type: String,
            required: true,
        },
        schoolDomain: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            itemPerPage: 8,
            selectedCategory: "",
            selectedCategoryName: "all",
            page: 1,
            numberOfPages: 0,
            news: [],
            showLoadMore: false,
            windowWidth: window.innerWidth,
            totalResults: 1,
        };
    },

    computed: {
        categories() {
            return this.categoriesList;
        },
        absolutePath() {
            return window.location.href.substring(
                0,
                window.location.href.indexOf("?")
            );
        },
    },
    methods: {
        async fetchNews(requestType = null) {
            this.loading = true;

            const myHeaders = new Headers();
            myHeaders.append(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );

            const urlencoded = new URLSearchParams();
            urlencoded.append("page", this.page);
            urlencoded.append("lang", this.currentLang || "en");
            urlencoded.append(
                "filter",
                this.selectedCategory ? this.selectedCategory : ""
            );
            urlencoded.append("items-per-page", this.itemPerPage.toString());
            urlencoded.append("return-all-news", this.returnAllNews);
            urlencoded.append("school-domain", this.schoolDomain);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            try {
                const response = await fetch("/api/get-news", requestOptions);
                const result = await response.json();

                if (result.totalResults <= this.itemPerPage) {
                    this.numberOfPages = 1;
                } else if (
                    result.totalResults > this.itemPerPage &&
                    result.totalResults % this.itemPerPage === 0
                ) {
                    this.numberOfPages = parseInt(
                        result.totalResults / this.itemPerPage
                    );
                } else {
                    this.numberOfPages =
                        Math.floor(result.totalResults / this.itemPerPage) + 1;
                }

                if (requestType === "loadMore") {
                    // nel caso di load more aggiungo le nuove news alla lista esistente
                    this.news = this.news.concat(result.news);
                } else {
                    this.news = result.news;
                    this.totalResults = result.totalResults;
                }

                // recupero info per gestire la visibilità del button "Load more"
                this.showLoadMore = result.showLoadMore;

                if (
                    Math.ceil(
                        parseInt(this.totalResults) / parseInt(this.itemPerPage)
                    ) > 1 &&
                    this.page > 1
                ) {
                    this.removeLinkFromHead("prev");
                    const link = document.createElement("link");
                    link.href = `${this.absolutePath}?page=${this.page - 1}`;
                    link.rel = "prev";
                    document.getElementsByTagName("head")[0].appendChild(link);
                }

                if (
                    Math.ceil(
                        parseInt(this.totalResults) / parseInt(this.itemPerPage)
                    ) > 1 &&
                    this.page <
                        Math.ceil(
                            parseInt(this.totalResults) /
                                parseInt(this.itemPerPage)
                        )
                ) {
                    this.removeLinkFromHead("next");
                    const link = document.createElement("link");
                    link.href = `${this.absolutePath}?page=${this.page + 1}`;
                    link.rel = "next";
                    document.getElementsByTagName("head")[0].appendChild(link);
                }

                if (
                    this.page ===
                    Math.ceil(
                        parseInt(this.totalResults) / parseInt(this.itemPerPage)
                    )
                ) {
                    this.removeLinkFromHead("next");
                }

                // Aggiornamento dell'URL con i nuovi parametri
                this.updateUrl();
            } catch (error) {
                console.error(props.errorGettingNews, error);
            } finally {
                this.loading = false;
            }
            if (this.selectedCategory) {
                document
                    .querySelector("#filter-news-0")
                    ?.classList.remove("filter-active");
                document
                    .querySelector(`#filter-news-${this.selectedCategory}`)
                    ?.classList.add("filter-active");
            } else {
                document
                    .querySelector("#filter-news-0")
                    ?.classList.add("filter-active");
            }
        },

        async onChangeCategory(idCategory) {
            this.page = 1;
            this.news = [];
            this.loading = true;
            this.returnAllEvents = false;
            if (this.selectedCategory === idCategory) {
                // se la category è già selezionata la rimuovo
                this.selectedCategory = "";
                document
                    .querySelector(`#filter-news-${idCategory}`)
                    ?.classList.remove("filter-active");
            } else {
                this.selectedCategory = idCategory;
                const buttons = Array.from(
                    document.querySelectorAll(".btn-listing-filter")
                );
                buttons.map((element) => {
                    if (element?.classList.contains("filter-active")) {
                        element?.classList.remove("filter-active");
                    }
                });
                document
                    .querySelector(`#filter-news-${idCategory}`)
                    ?.classList.add("filter-active");
            }
            await this.fetchNews();
        },

        async loadMore() {
            this.page++;
            this.returnAllEvents = false;
            await this.fetchNews("loadMore");
        },

        updateUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            if (this.page !== 1) {
                urlParams.set("page", this.page);
            } else {
                urlParams.delete("page");
            }
            if (this.selectedCategory !== "" && this.selectedCategory !== 0) {
                urlParams.set(
                    "category",
                    this.categoriesList[this.selectedCategory]
                );
            } else {
                // Rimuovi il parametro 'category' se il filtro non è applicato
                urlParams.delete("category");
            }

            const queryString = urlParams.toString();
            const newUrl = `${location.pathname}${
                queryString ? `?${queryString}` : ""
            }`;
            history.pushState({}, "", newUrl);
        },

        parseUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            const page = urlParams.get("page");
            const currentCategory = urlParams.get("category");
            if (page) this.page = parseInt(page);

            if (currentCategory) {
                Object.keys(this.categoriesList).forEach((key) => {
                    if (
                        this.categoriesList[key].toLowerCase() ===
                        currentCategory.toLowerCase()
                    ) {
                        this.selectedCategory = key;
                    }
                });
            }
        },

        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },

        formatDate(dateString, monthString) {
            let str = dateString.replace(/\s/g, "");
            const myArray = str.split("-");
            let formattedDate = `${myArray[0]} ${monthString} ${myArray[2]}`;
            return formattedDate;
        },

        removeLinkFromHead(attributeName) {
            const linkElements = document.head.querySelectorAll("link");

            linkElements.forEach((link) => {
                if (link.getAttribute("rel") === attributeName) {
                    link.remove();
                }
            });
        },
    },

    mounted() {
        window.addEventListener("resize", this.updateWindowWidth);

        // Recupero gli eventi all'atterraggio in pagina
        this.parseUrl();
        if (this.page > 1) {
            this.returnAllNews = true;
        }

        // Recupero le news all'atterraggio in pagina
        this.fetchNews();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.updateWindowWidth);
    },
};
</script>
