<template>
    <!-- Search Desktop -->
    <div class="search-section-desktop mb-4 d-none d-xl-flex justify-content-center position-relative w-100">
        <div class="input-group search-field-desktop row p-0">

            <div class="col-12 d-flex px-0">
                <input type="search"
                    class="form-control form-control-lg shadow-none search-field-input-desktop col-6 h-100 ps-4" name="search"
                    :placeholder="searchAltText"  @keydown.enter="oramaSearch" v-model="searchTerm" />

                <button v-if="results" href="" class="d-flex search-icon-container-desktop h-100 m-0 justify-content-end align-items-center"
                    role="button">
                    <img src="../../../public/images/reset.svg" width="24" height="24"
                        v-on:click="resetResults" :alt= "closeAltText" />
                </button>
                <button v-else class="d-flex search-icon-container-desktop h-100 m-0 justify-content-end align-items-center" v-on:click="oramaSearch"
                    title=""><img src="../../../public/images/search.svg" width="24" height="24" :alt=" searchAltText"/></button>
            </div>
        </div>

        <div v-if="loading" class="row search-results-wrapper-desktop">
            <div class="d-flex justify-content-center align-items-center">
                <div class="spinner-border color-school-text" role="status"></div>
            </div> 
        </div>
        

        <div v-if="results?.length > 0" class="row search-results-wrapper-desktop justify-content-center">
            <a class="py-3 search-single-result-desktop ps-4" v-for="result in results" v-bind:key="result.id"
                :href="result.document.url">
                <p class="mb-0 search-title"  v-html="highlight(result.document.title)"></p>
                <p class="mb-0 search-par" v-html="highlight(result.document.description)"></p>
            </a>
        </div>
        <div v-else-if="results?.length === 0 && !loading" class="row search-results-wrapper-desktop justify-content-center">
            <p class="py-3 text-center">{{noResults }}</p>
        </div>
    </div>
    <!-- Search Mobile -->
    <div class="search-section-mobile d-flex d-xl-none my-4 position-relative w-100 align-items-center">
        <div class="input-group">
            <input type="search" class="form-control form-control-lg shadow-none search-input-field w-100" name="search"
                :placeholder="searchAltText"  @keydown.enter="oramaSearch" v-model="searchTerm" />
        </div>

        <button v-if="results" class="d-flex d-xl-none btn btn-primary btn-school-icon"
            role="button">
            <img src="../../../public/images/close-white.svg" width="24" height="24" v-on:click="resetResults" :alt=" closeAltText " />
        </button>
        <button v-else class="d-flex d-xl-none btn btn-primary btn-school-icon" v-on:click="oramaSearch" title=""><img
                src="../../../public/images/search-white.svg" width="24" height="24" :alt="searchAltText" /></button>

        <div class="search-results-wrapper" v-if="loading || results?.length >= 0">
            <div v-if="loading">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="spinner-border color-school-text" role="status"></div>
                </div> 
            </div>     
            <div v-if="results?.length > 0" class="h-100 overflow-y-auto">
                <a class="col-12 py-3 search-single-result ps-4 d-block" v-for="result in results" v-bind:key="result.id"
                    :href="result.document.url">
                    <p class="mb-0 search-title" v-html="highlight(result.document.title)"></p>
                    <p class="mb-0 search-par" v-html="highlight(result.document.description)"></p>
                </a>
            </div>
            <div v-else-if="results?.length === 0" class="row col-12 justify-content-center">
                <p class="py-3 text-center">{{noResults }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { OramaClient } from '@oramacloud/client'

export default {
    name: "OramaSearch",
    props: {
        locale: {
            type: String,
            required: true
        },
        searchAltText: {
            type: String,
            required: true
        },
        noResults: {
            type: String,
            required: true
        },
        closeAltText: {
            type: String,
            required: true
        },
        oramaEndpoint:{
            type: String,
            required: true
        },
        oramaPublicKey:{
            type: String,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            searchTerm: null,
            results: null
        }
    },
    created() {
        this.client = new OramaClient({
            endpoint: this.oramaEndpoint,
            api_key: this.oramaPublicKey
})
    },
    methods: {
        oramaSearch() {
            // if (this.searchTerm && this.searchTerm.length > 3) {
            if (this.searchTerm && this.searchTerm.length > 0) {
                this.loading = true;
              
                // disable scroll of first level menu mobile
                let headerCollapsibleFirstLevel = document.getElementById('navbarCollapseHeader')
                headerCollapsibleFirstLevel.style.overflow = 'hidden';

                this.results = this.client.search({
                    term: this.searchTerm,
                    limit: 5,
                    mode: 'hybrid' // can be 'fulltext', 'vector' or 'hybrid'
                }).then((results) => {
                    this.results = results.hits.filter((element) => element.document.locale === this.locale);
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                })
            } else {
                this.results = null;
            }
        },

        highlight(text) {
      if (!this.searchTerm) {
        return text;
      }
      const regex = new RegExp(`(${this.escapeRegExp(this.searchTerm)})`, 'gi');
      return text.replace(regex, '<b>$1</b>');
        },
        escapeRegExp(string) {
         return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },

        resetResults() {
            this.results = null;
            this.searchTerm = null;
            // restart the scroll of first level menu mobile
            let headerCollapsibleFirstLevel = document.getElementById('navbarCollapseHeader')
            headerCollapsibleFirstLevel.style.overflow = 'auto';
        }
    }
}
</script>