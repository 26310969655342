import { createApp } from 'vue/dist/vue.esm-bundler.js';
import jQuery from "jquery";
window.jQuery = jQuery;
import select2 from 'select2';
window.Select2 = select2;

var $ = jQuery;
const app = createApp({
    data() {
        return {}
    }
});

app.directive('select', {
    beforeMount: function (el, binding, vnode) {
        $(el).select2().on("select2:select", (e) => {
            el.dispatchEvent(new Event('change', { target: e.target }));
        });
    },
    updated: function (el) {
        $(el).trigger("change");
    }
});

$('#supplier_id').select2({});

app.component('oramasearch', require('./components/OramaSearch.vue').default);
app.component('listingevents', require('./components/ListingEvents.vue').default);
app.component('listingnews', require('./components/ListingNews.vue').default);
app.mount('#vueApp')